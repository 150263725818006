<template lang="pug">
  #welcome-checklist.step-component
    h2 Do these before you begin.

    .checklist-item(v-for="(instruction, index) in instructions" :key="index")
      icon(:data="icon(instruction.icon)")
      | {{ instruction.text }}

    button.button(@click="gotoNext()") I’m Ready
</template>

<script>
export default {
  props: {
    instructions: { type: Array, required: true },
  },

  data() {
    return {
      // icon: null,
    }
  },

  computed: {},

  watch: {},

  methods: {
    gotoNext,
    icon,
  },

  components: {},
}

/* Computed ---------------------------------------------------- */
/* Watch ------------------------------------------------------- */
/* Methods ----------------------------------------------------- */
function gotoNext() {
  this.$emit('complete')
}

function icon(icon) {
  return require(`@icon/${icon}`)
}
</script>
